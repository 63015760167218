import React from "react";
import './Footer.css'
const Footer = ()=>{
    return(
        <>
        <section classNameName="footer">
            <div classNameName="container">
            <footer className="footer--light">
    <div className="footer-big">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="footer-widget">
              <div className="widget-about">
              <img
            className="w-100 animate__animated animate__delay-1s animate__fadeIn"
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="Mobirise Website Builder"
          />
                <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                <ul className="contact-details d-none">
                  <li>
                    <i className="fas fa-phone-alt"></i> Call Us:
                    <a href="#">011-23456789</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope-open-text"></i>
                    <a href="#">support@ibs.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="footer-widget">
              <div className="footer-menu">
                <h4 className="footer-widget-title">Popular Category</h4>
                <ul>
                  <li>
                    <a href="#">ReactJS Template</a>
                  </li>
                  <li>
                    <a href="#">ReactJS Template</a>
                  </li> <li>
                    <a href="#">ReactJS Template</a>
                  </li> <li>
                    <a href="#">ReactJS Template</a>
                  </li> <li>
                    <a href="#">ReactJS Template</a>
                  </li> 
                 
                </ul>
              </div>
              
            </div>
        
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="footer-widget">
              <div className="footer-menu">
                <h4 className="footer-widget-title">Our Company</h4>
                <ul>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">How It Works</a>
                  </li>
                  <li>
                    <a href="#">Affiliates</a>
                  </li>
                  <li>
                    <a href="#">Testimonials</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">Plan & Pricing</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="footer-widget">
              <div className="footer-menu p-0">
                <h4 className="footer-widget-title">Help Support</h4>
                <ul>
                  <li>
                    <a href="#">Support Forum</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Support Policy</a>
                  </li>
                  <li>
                    <a href="#">FAQs</a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="mini-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="copyright-text">
              <p>© 2024
                <a href="#">AI Story Generator</a>. All rights reserved. Created by
                <a href="#">IBS</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
            </div>
        </section>
        </>
    )
}

export default Footer