import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./storyCard.css";

const StoryCard = (props) => {
  const { story, baseAPIUrl } = props;
  const navigate = useNavigate();
  const [like, setLike] = useState(10);
  const [isLike, setIsLike] = useState(false);
  const token = localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(token ? true : false);
  const clickLike = () => {
    if (isLogin) {
      setLike(like + (isLike ? -1 : 1));
      setIsLike(!isLike);
    } else {
      navigate("/login");
    }
  };
  const handleSubmit = (storyID) => {
    // Perform some action here
    navigate("/story/" + story.id); // Navigate programmatically to '/success' route
  };
  return (
    <div className="item features-image">
      <a
        className="story_continue"
        href="file:///tmp/Mobirise/20240212_112046/index.html"
      >
        <div className="item-wrapper">
          <div onClick={handleSubmit}>
            <div className="item-img">
              <img
                className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                src={story.media[0]?.image_file_url}
                alt={story.title}
              />
              {/* <img
            className="w-100 animate__animated animate__delay-1s animate__fadeIn"
            src={`${process.env.PUBLIC_URL}/images/bookCover.png`}
            alt="Mobirise Website Builder"
          /> */}
            </div>
            <div className="item-content">
              <h5 className="item-title ">
                {story && <strong>{story.title}</strong>}
              </h5>
              <div className="item-body">
                <p className="sometihingaboutstory">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  eiusmod tempor
                </p>
              </div>
            </div>
          </div>
          <div className="item-footer">
            <div className="d-flex align-items-center justify-content-between">
              <div className="pubish">
                <p className="author">
                  {" "}
                  <strong> Creator:</strong>
                  {story.creator_name}
                </p>
              </div>
              <div className="d-flex views">
                <strong>Views:</strong>
                <span className="date">1k</span>
              </div>
              <div className="likes d-flex">
                <i
                  onClick={clickLike}
                  className={
                    "fa-regular fa-heart me-1 " + (isLike ? "liked" : "")
                  }
                ></i>
                {/* <strong>Likes:</strong> */}
                <span className="date">{like}</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default StoryCard;
