import axios from 'axios';
import config from "./config";
const { API_BASE_URL } = config;
console.log(API_BASE_URL)

// Set up a default URL and other configurations
const API = axios.create({ baseURL: API_BASE_URL + '/api/v1/' });
const USERAPI = axios.create({ baseURL: API_BASE_URL + '/api/user/' })
const baseUrl = axios.create({ baseURL: API_BASE_URL + '/' })


// Define and export your API methods
export const fetchStories = () => API.get('published/stories/');
export const fetchStory = (story_id) => API.get('stories/' + story_id + '/');
export const fetchChapter = (chapter_id) => API.get('chapters/' + chapter_id + '/');
export const createStory = (newStory) => API.post('stories/', newStory);
export const createChapter = (story_id) => API.post('stories/' + story_id + '/chapters/', { story_id: story_id });
export const publishedChapter = (chapter_id, draft_mode) => API.put('chapters/' + chapter_id + "/", { chapter_id: chapter_id, draft_mode: draft_mode });
export const regenerateChapterMedia = (chapter_id, media_id) => API.post('chaptermedia/update_by_chapter/', { chapter_id: chapter_id, media_id: media_id })
export const publishAll = (story_id) => API.put('chaptermedia/publish_all_chapters/', { story_id: story_id })
export const searchStory = (params) => API.get("stories/search/", { params })
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
// export const userLogin = (email, password, firstname, lastname) => USERAPI.post(`change-password/`, { email: email, password: password, firstname: firstname, lastname: lastname })

export const userLogin = (data) => baseUrl.post('/login/user/', data)