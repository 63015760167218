import React, { useEffect, useState } from "react";
import Loader from "./loader";
import "./stepByStepLoader.css";
import { Link, useNavigate } from "react-router-dom";

const StepByStepIndicator = (props) => {
  const { currentStep, storyId } = props;
  const [loadingStateMessage, setLoadingStateMessage] = useState({
    story_step: "",
    media_step: "",
    overall_step: "",
  });

  const navigate = useNavigate();
  const handleOnClick = () => {
    if (storyId) {
      navigate("/story/" + storyId);
    }
  };

  useEffect(() => {
    let updated = {};
    switch (currentStep) {
      case 1:
        updated.story_step = "Story Creating";
        updated.media_step = "Waiting";
        updated.overall_step =
          "Great news! Your request has been successfully accepted. Stay tuned for updates.";
        break;
      case 2:
        updated.story_step = "Story Created";
        updated.media_step = "Creating Media";
        updated.overall_step =
          "Exciting progress! Your story has been crafted and media creation is underway. Anticipate the magic!";
        break;
      default:
        updated.story_step = "Story Created";
        updated.media_step = "Media Created";
        updated.overall_step =
          "Congratulations! Your journey has reached its splendid completion. Enjoy the final masterpiece.";
    }
    setLoadingStateMessage(updated);
  }, [currentStep]);
  return (
    <div className="container">
      <div className="loader_wapper">
      <div className="stepper-wrapper">
        <div className={`stepper-item ${currentStep >= 1 ? "completed" : ""}`}>
          <div className="step-counter">1</div>
          <div className="step-name">Ininitiated</div>
        </div>
        <div
          className={`stepper-item ${currentStep == 1 ? "active" : ""} ${
            currentStep >= 2 ? "completed" : ""
          }`}
        >
          <div className="step-counter">2</div>
          <div className="step-name">{loadingStateMessage.story_step}</div>
        </div>
        <div
          className={`stepper-item ${currentStep == 2 ? "active" : ""} ${
            currentStep >= 3 ? "completed" : ""
          }`}
        >
          <div className="step-counter">3</div>
          <div className="step-name">{loadingStateMessage.media_step}</div>
        </div>
      </div>
      <div className="progress-container"></div>
      <div className="step-container">
        <fieldset>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: 750,
              margin: "20px auto",
            }}
          >
            <h2 className="success_message">
              {loadingStateMessage.overall_step}
            </h2>
          </div>

          {currentStep && currentStep < 3 && <Loader />}
          {currentStep && currentStep == 3 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={handleOnClick} className="createBtn">
               <span> View Your Story </span>
              </button>
            </div>
          )}
        </fieldset>
      </div>
    </div>
    </div>
  );
};

export default StepByStepIndicator;
