import React, {useEffect, useState} from "react";
import './TextToParagraph.css'

function chunkSentencesByPeriod(text) {
    // Split the text into sentences using period followed by space or end of text
    const sentences = text.split(/(?<=\.)\s+/);

    // Array to hold the chunks of up to three sentences each
    const chunks = [];

    // Iterate through sentences, grouping them into chunks of up to three sentences each
    for (let i = 0; i < sentences.length; i += 3) {
        // Join up to the next three sentences into a single string
        const chunk = sentences.slice(i, i + 3).join(' ');
        chunks.push(chunk);
    }

    // Check if the last chunk contains "To Be Continued" or "The End"
    const lastChunk = chunks[chunks.length - 1];
    if (lastChunk.includes("To be continued")) {
        // Split the last chunk and add "To Be Continued..." as a new chunk
        const newChunks = lastChunk.split("To be continued");
        chunks[chunks.length - 1] = newChunks[0].trim();
        chunks.push("To be continued...");
    } else if (lastChunk.includes("The End")) {
        // Split the last chunk and add "The End" as a new chunk
        const newChunks = lastChunk.split("The End");
        chunks[chunks.length - 1] = newChunks[0].trim();
        chunks.push("The End");
    }

    return chunks;
}

const TextToParagraph = ({text})=> {
    const [paragraph, setParagraph] = useState([])
    useEffect(()=> {
        if (text && text.length) {
            setParagraph(chunkSentencesByPeriod(text))
        } 
    },[text])
    return paragraph.map((sentence, idx)=> <p className="chapter-content" key={idx}>{sentence}</p>)
}

export default TextToParagraph;