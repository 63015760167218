import React, { useEffect } from "react";
import "./ParentsLogin.css";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { userLogin } from "./../api";
// import Otp from "../components/Otp";

const ParentsLogin = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(token ? true : false);
  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  });
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
    grant_type: "password",
    client_id: "gY7mwwOPhbuiYuASBXj9Sb6AkejDWz8Ofj9jeeck",
    client_secret:
      "Orouk3ZCjNCkEs2uCo56okOuJwP4IAVSz1datu6u6COvBSxFUMVEfXrTPkHQR1VBj40mBWkhBa2lyEtJ90sljUPau3z39YBxm4s1uivgMFvbiVJXiXMtFY7ARP8kbhuW",
  });

  const [didEdit, setDitEdit] = useState({
    username: false,
    password: false,
  });

  const getLoingData = (e) => {
    // e.preventDefault();
    const { value, name } = e.target;
    setInputValue(() => {
      return {
        ...inputValue,
        [name]: value,
      };
    });
    setDitEdit((prevEdit) => ({
      ...prevEdit,
      [name]: false,
    }));
  };

  const Login = (e) => {
    e.preventDefault();
    const {
      username,
      password,
      grant_type,
      client_id,
      client_secret,
    } = inputValue;
    console.log(inputValue);

    userLogin(inputValue).then((res) => {
      console.log("status code is " + res);
      if (res.status === 200) {
        localStorage.setItem("token", JSON.stringify(res.data.access_token));
        localStorage.setItem("user_login_data", JSON.stringify(res.data));
        navigate("/");
        window.location.reload();
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleInputBlur = (e) => {
    const { name } = e.target;
    setDitEdit((prevEdit) => ({
      ...prevEdit,
      [name]: true,
    }));
  };
  return (
    <>
      <Outlet />
      <section className="loginSingupWapper">
        <div className="container">
          <div className="d-flex align-items-center full-height">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <img
                  className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                  src={`${process.env.PUBLIC_URL}/images/signIn.svg`}
                  alt="Mobirise Website Builder"
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="signup">
                  <div className="overlay">
                    <h3>
                      <strong>Sign In</strong>
                      Explore Our Enchanted Library
                    </h3>
                    {/* <button onClick={onSubmit}>Submit</button> */}
                    <form action="#">
                      <div className="inp-wrapper">
                        <input
                          onChange={getLoingData}
                          name="username"
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email Address"
                          maxlength="50"
                        />
                        <div className="error-msg d-none d-flex justify-content-between">
                          <p> Please enter a valid email address </p>
                        </div>
                      </div>

                      <div className="inp-wrapper">
                        <input
                          name="password"
                          onChange={getLoingData}
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="password"
                          maxlength="50"
                        />
                        <div className="error-msg d-none d-flex justify-content-between">
                          <p> Please enter a valid password</p>
                        </div>
                      </div>
                      <div className="imp-wrapper firstnamelastname d-none">
                        <div className="d-flex">
                          <input
                            name="firstname"
                            type="text"
                            id="firstname"
                            placeholder="First Name"
                          />
                          <input
                            name="lastname"
                            type="text"
                            id="lastname"
                            placeholder="Last Name"
                            minLength={8}
                            maxLength={20}
                          />
                        </div>
                      </div>
                      <div className="trems_tags mb-5">
                        <div className="tacbox d-flex align-items-baseline mb-2">
                          <input
                            id="Remember"
                            name="checkbox"
                            type="checkbox"
                          />
                          <label htmlFor="Remember">Remember me</label>
                        </div>
                        <div className="tacbox d-flex">
                          <input
                            id="checkbox"
                            name="checkbox"
                            type="checkbox"
                          />
                          <label htmlFor="checkbox">
                            {" "}
                            Send me newsletten and keep me update on daily basis
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center createBtn_wapper">
                        <button
                          onClick={Login}
                          name="submit"
                          type="submit"
                          className="btn btn-black createBtn"
                        >
                          {" "}
                          <Link>
                            <span>Agree &amp; Sign In </span>
                          </Link>
                        </button>
                      </div>
                    </form>
                    <div className="haveAccount">
                      New User
                      <Link to="/registration">Create Account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ParentsLogin;
