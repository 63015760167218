import React from "react";
import { useNavigate } from "react-router-dom";
import "./features.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "./Slider";
import Slider from "react-slick";
const Features = (props) => {
  const { storyInfo, baseAPIUrl } = props;
  const navigate = useNavigate();
  const handleOnClick = (storyId) => {
    navigate("/story/" + storyId);
  };
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 6,
    swipeToSlide: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      data-bs-version="5.1"
      className="features15 cid-u40iNVW1EK"
      id="features015-1s"
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 content-head">
            <div className="mbr-section-head">
              <h4 className="more_stories">
                <strong>More Stories</strong>
              </h4>
            </div>
          </div>
        </div>
        <div className="d-fldex flex-wrap justify-content-betsween gap">
          <Slider {...settings}>
            {storyInfo &&
              storyInfo
                .reverse()
                .slice(0, 10)
                .map((story, idx) => (
                  <div className="item features-without-image" key={idx}>
                    <div
                      className="item-wrapper feature-story-wrapper"
                      onClick={() => handleOnClick(story.id)}
                    >
                      <div className="img-wrapper">
                        <img
                          className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                          src={story.media}
                          alt="Mobirise Website Builder"
                        />
                        {/* <img
            className="w-100 animate__animated animate__delay-1s animate__fadeIn"
            src={`${process.env.PUBLIC_URL}/images/bookCover.png`}
            alt="Mobirise Website Builder"
          /> */}
                      </div>
                      <div className="card-box">
                        <h4 className="card-title mbr-fonts-style mb-0 display-7">
                          <strong>{story.title}...</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Features;
