import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Otp.css";
import axios from "axios";
const Otp = () => {
  const api = "https://dev-kidsplanet.impressicocrm.com/api/user/otp/";
  const user_id = localStorage.getItem("user_id");
  console.log(user_id);
  const [otpVerify, setOtpVerify] = useState({
    user_id: user_id,
    otp: "",
  });
  const resendOtp = {
    user_id: user_id,
    otp_type: "email",
    purpose: "emailverify",
  };
  const getData = (e) => {
    const { value, name } = e.target;
    setOtpVerify(() => {
      return {
        ...otpVerify,
        [name]: Number(value),
      };
    });
  };

  const handelVerify = (e) => {
    e.preventDefault();
    axios
      .post(`${api}verify/`, otpVerify)

      .then((res) => {
        console.log("status code is " + res.status);
        console.log(res);
        if (res.status === 200) {
          window.location.href = "/";
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
    console.log(otpVerify);
  };

  function resend() {
    axios
      .post(`${api}regenerate/`, resendOtp)
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((erroe) => {
        console.log(erroe);
      });
  }
  return (
    <>
      <section className="opt">
        <div className="container">
          <div
            className="row justify-content-md-center align-items-center"
            id="OTP-wapper"
          >
            <div className="col-12 col-lg-6">
              <img
                className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                src={`${process.env.PUBLIC_URL}/images/otp.svg`}
                alt="Mobirise Website Builder"
              />
            </div>
            <div className="col-lg-6 text-center">
              <div className="row">
                <div className="col-sm-12 bgWhite">
                  <div className="title">
                    <h4> Verify OTP</h4>
                    <p>
                      Please enter the One-Time Password (OTP) sent to your
                      registered mobile number/email address to verify your
                      identity.
                    </p>
                  </div>
                  <form action="" onSubmit={handelVerify} className="mt-5 mb-3">
                    <input
                      type="text"
                      id="otpInput"
                      name="otp"
                      className="otp"
                      onChange={getData}
                    />
                    {/* <input className="otp" type="text"  maxlength={1} />
              <input className="otp" type="text"  maxlength={1} />
              <input className="otp" type="text"  maxlength={1} /> */}
                    <p className="resend mt-3">
                      Didn't receive the OTP?{" "}
                      <Link onClick={resend}> Click here to resend.</Link>
                    </p>

                    {/* <Link to="/"> */}
                    <button type="submit" className="createBtn">
                      {" "}
                      <span> Verify </span>
                    </button>
                  </form>
                  {/* </Link> */}
                  <Link className="d-block trouble">
                    Having trouble? Contact support for assistance.
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Otp;
