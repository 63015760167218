import React from "react";
import './LoaderIcon.css'
const LoaderIcon = () => {
  return (
    <div
      className="spinner-border"
      style={{width: "3rem", height: "3rem", borderRightColor: "pink"}}
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  );
};
export default LoaderIcon;