import React, { useState, useEffect, useCallback } from "react";
import "./createStoryForm.css";
import Loader from "./loader";
import { createChapter, createStory, searchStory } from "./../api";
import StepByStepIndicator from "./stepByStepLoader";
import SearchStory from "./searchStory";
import { debounce, throttle } from "lodash";

const CreateStory = () => {
  const [story, setStory] = useState([]);
  // const [chapters, setChapters] = useState([]);
  const [isLoad, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [suggestedStories, setSuggestedStories] = useState(null);
  const [isApiFailed, setIsApiFailed] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showSugestion, setShowSugestion] = useState(true)
  const [formData, setFormData] = useState({
    creator_name: "",
    genre: "",
    age_range: "",
    number_of_chapters: "",
    user_preference: "",
  });

  // Define a throttled version of your API call
  const throttledApiCall = useCallback(
    throttle(async (newFormData) => {
      const tempData = {...newFormData,}
      // Make your API call here with newFormData
      delete tempData.creator_name;
      const hasValue = Object.values(tempData).some((value) => value !== "");
      if (hasValue) {
        const { data } = await searchStory(tempData);
        setSuggestedStories(data.length ? data : null);
      } else {
        setSuggestedStories(null);
      }
    }, 2000),
    []
  );

  const convertErrorMessage = (errors) => {    
    if (typeof errors === "string") {
      if (errors === 'client_error') {
        return ['Please correct the errors before submitting.']
      }
      return ["Oops! Something went wrong. Please try again later!"]
    }
    return Object.keys(errors).map((key) => {
      // Convert the first character of the key to uppercase for display
      let displayKey = key.replace("_", " "); // Replace underscores with spaces for better readability
      displayKey = displayKey.charAt(0).toUpperCase() + displayKey.slice(1); // Capitalize the first letter

      // Join all error messages for this key, assuming there might be more than one error message per field
      const messages = errors[key].join(", ");

      // Return the formatted string
      return `${displayKey}: ${messages}`;
    });
  };
 
  const [clientErrors, setClientErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    setErrors(null)

    // Resetting errors and API failure state on each change
    setIsApiFailed(false);

    // Initialize a new errors object
    const newErrors = { ...clientErrors };

    // Validate number of chapters
    if (name === 'number_of_chapters' && (value > 4 || value < 1)) {
      newErrors.number_of_chapters = 'Number of chapters must be between 1 and 4';
    } else {
      delete newErrors.number_of_chapters; // Remove error if input is valid
    }

    // Validate age range
    if (name === 'age_range' && (value > 7 || value < 0)) {
      newErrors.age_range = 'Age range must be between 1 and 7';
    } else {
      delete newErrors.age_range; // Remove error if input is valid
    }

    // Update the errors state
    setClientErrors(newErrors);

    // If there are no errors, proceed with the API call
    // if (Object.keys(newErrors).length === 0) {
    //   throttledApiCall(newFormData);
    //   setClientErrors({}); // Ensure any previous errors are cleared if all inputs become valid
    // }
  };

  const handleOnFocus = (e) => {
    // setShowSugestion(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submit action
    const hasValue = Object.values(formData).some((value) => value !== "");
    if (Object.keys(clientErrors).length > 0) {
      setErrors('client_error');
      return; 
    }
  
    if (hasValue) {
      setShowForm(false);
      setIsLoading(true);
      setIsApiFailed(false)
      generateStory();
    }
    // Here you can add actions to be taken after form submission like sending data to an API
  };

  const generateStory = async () => {
    
    createStory(formData).then(
      (response) => {
        setStory(response.data);
        setCurrentStep(2);
        setErrors(null);
      },
      (err) => {
        setIsApiFailed(true)
        setShowForm(true);
        setIsLoading(false);
        setErrors(err.response.data);
      }
    );
  };

  useEffect(() => {
    if (!story.id) return;
    setIsApiFailed(false)
    const generateNewChapters = async () => {
      const { data } = await createChapter(story.id);
      setCurrentStep(3);
    };

    generateNewChapters();
  }, [story]);

  return (
    <>
      {showForm && (
        <section class="login_section">
          <div className="loginOuterDiv">
            <div className="container">
              <div className="login_wapper d-flex align-items-center justify-content-center">
                <div className="input_wapper position-relative">
                  <h4>Generate Your Story</h4>
                  {errors && (
                    <div class="alert cm-alert" role="alert">
                      {convertErrorMessage(errors).map((error, idx) => <p className="error-message" key={idx}>{error}</p>)}
                    </div>
                  )}
                  <form action="#" class="form">
                    <div class="input-box">
                      <label>Creator Name (optional):</label>
                      <input
                        type="text"
                        placeholder="Enter your name"
                        name="creator_name"
                        value={formData.creator_name}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                      />                      
                    </div>
                    <div class="input-box">
                      <label>Genre:</label>
                      <input
                        type="text"
                        placeholder="Enter genre, eg. jungle"
                        required
                        name="genre"
                        value={formData.genre}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                      />
                    </div>
                    <div class="input-box">
                      <label>Age:</label>
                      <input
                        type="text"
                        placeholder="Enter age, eg. 5 or 6"
                        required
                        name="age_range"
                        value={formData.age_range}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                      />
                      {clientErrors.age_range && <div style={{color: 'red'}}>{clientErrors.age_range}</div>}
                    </div>
                    <div class="input-box address">
                      <label>Total Chapters:</label>
                      <input
                        type="text"
                        placeholder="Total chapters"
                        required
                        name="number_of_chapters"
                        value={formData.number_of_chapters}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                      />
                      {clientErrors.number_of_chapters && <div style={{color: 'red'}}>{clientErrors.number_of_chapters}</div>}
                    </div>
                    <div class="input-box address">
                      <label>Any Preference:</label>
                      <input
                        type="text"
                        placeholder="Any particular"
                        required
                        name="user_preference"
                        value={formData.user_preference}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                      />
                    </div>
                    {/* <button onClick={handleSubmit}>Submit</button> */}
                    <button onClick={handleSubmit} className="createBtn"> <span>Submit</span></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isLoad && (
        <StepByStepIndicator currentStep={currentStep} storyId={story.id} />
      )}
      {/* {suggestedStories && showSugestion && !isApiFailed && <SearchStory results={suggestedStories} onClickShowSuggsetion={setShowSugestion}/>} */}
    </>
  );
};

export default CreateStory;
