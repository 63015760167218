import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./menu.css";
const MenuList = () => {
  let banner = document.getElementById("header12-1q");
  const token = localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(token ? true : false);
  const [openProfile, setOpenProfile] = useState(false);
  const navigate = useNavigate();
  const [scrolltop, setscrolltop] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setscrolltop("");
      } else {
        setscrolltop("fixed");
      }
    });
  }, [isLogin]);

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
    navigate("/login");
  };
  return (
    <section
      data-bs-version="5.1"
      className="menu menu4 cid-u40iz8X2iD"
      once="menu"
      id="menu04-1p"
    >
      <nav
        className={`${scrolltop} navbar navbar-dropdown navbar-fixed-top  navbar-expand-lg opacityScroll`}
      >
        <div className="container navbarWapper">
          <Link to="/">
            <div className="navbar-brand">
              <img
                className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Mobirise Website Builder"
              />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-bs-toggle="collapse"
            data-target="#navbarSupportedContent"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className="collapse navbar-collapse opacityScroll"
            id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav nav-dropdown navbar-nav-top-padding"
              data-app-modern-menu="true"
            >
              <li className="nav-item">
                <Link to="/" className="nav-link link  icon">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/search-story" className="nav-link link  icon">
                  Search
                </Link>
              </li>
            </ul>

            <div className="navbar-buttons mbr-section-btn">
              {!isLogin ? (
                <Link to="/login" className="btn createBtn">
                  <span>Sign In</span>
                </Link>
              ) : (
                <div className="wapper-profile positon-relative">
                  <div
                    className="profile"
                    onClick={() => setOpenProfile((prev) => !prev)}
                  >
                    <img
                      src="https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-cartoon-man-avatar-vector-ilustration-png-image_6111064.png"
                      alt=""
                    />
                  </div>
                  {openProfile && (
                    <div className="profile_outer-div">
                      <div className="profile-list">
                        <Link onClick={logout} className="logoutbtn">
                          <span>Sign out</span>
                        </Link>
                        <Link to="/search-story" className="">
                          Search
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <Link to="/login" className="btn createBtn">
                <span>Sign In</span>
              </Link> */}
              {/* {isLogin && (
                <div className="wapper-profile positon-relative">
                  <div
                    className="profile"
                    onClick={() => setOpenProfile((prev) => !prev)}
                  >
                    <img
                      src="https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-cartoon-man-avatar-vector-ilustration-png-image_6111064.png"
                      alt=""
                    />
                  </div>
                  {openProfile && <div className="profile-list"></div>}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default MenuList;
