import React, { useRef, useEffect, useState } from "react";
import './Mp3AudioPlayer.css'

const Mp3AudioPlayer = (props) => {
    const AudioPlayer = useRef()
    const { file_url,thumbnail } = props
    console.log(thumbnail)
    const [isPlaying, setIsPlaying] = useState(false);
    const [songIndex, setSongIndex] = useState(2); // Initial song index
    const audioRef = useRef(null);
    const progressRef = useRef(null);

    // Song titles
    const songs = ['hey', 'summer', 'ukulele'];

    // Load song details into DOM
    const loadSong = (song) => {
        const audio = audioRef.current;
        audio.src = `music/${song}.mp3`;
        audio.load();
    };

    // Play song
    const playSong = () => {
        const audio = audioRef.current;
        audio.play();
        setIsPlaying(true);
    };

    // Pause song
    const pauseSong = () => {
        const audio = audioRef.current;
        audio.pause();
        setIsPlaying(false);
    };

    // Previous song
    const prevSong = () => {
        let newIndex = songIndex - 1;
        if (newIndex < 0) {
            newIndex = songs.length - 1;
        }
        setSongIndex(newIndex);
        loadSong(songs[newIndex]);
        playSong();
    };

    // Next song
    const nextSong = () => {
        let newIndex = songIndex + 1;
        if (newIndex >= songs.length) {
            newIndex = 0;
        }
        setSongIndex(newIndex);
        loadSong(songs[newIndex]);
        playSong();
    };

    // Update progress bar
    const updateProgress = () => {
        const audio = audioRef.current;
        const progress = progressRef.current;
        const { currentTime, duration } = audio;
        const progressPercent = (currentTime / duration) * 100;
        progress.style.width = `${progressPercent}%`;
    };

    // Set progress bar
    const setProgress = (e) => {
        const audio = audioRef.current;
        const progress = progressRef.current;
        const width = progress.clientWidth;
        const clickX = e.offsetX;
        const duration = audio.duration;
        audio.currentTime = (clickX / width) * duration;
    };

    // Event listeners
    const handlePlayPause = (event) => {
        event.stopPropagation();
        isPlaying ? pauseSong() : playSong();
    };
    return (
        <>
            {file_url ? <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <audio controls ref={AudioPlayer}>
                    <source src={file_url} type="audio/mpeg" />Your browser does not support the audio element.
                </audio>
            </div> : ''}
            {/* {file_url && <div className={`music-container ${isPlaying ? 'play': ''}`} id="music-container">
                <div className="music-info">
                    <h4 id="title">{songs[songIndex]}</h4>
                    <div className="progress-container" id="progress-container" onClick={setProgress}>
                        <div className="progress" id="progress" ref={progressRef}></div>
                    </div>
                </div>

                <audio src={file_url} id="audio" ref={audioRef} onTimeUpdate={updateProgress} onEnded={nextSong}></audio>

                <div className="img-container">
                    <img src={thumbnail} alt="music-cover" id="cover" />
                </div>
                <div className="navigation">
                    <button id="prev" className="action-btn" onClick={prevSong}>
                        <i className="fas fa-backward"></i>
                    </button>
                    <button id="play" className="action-btn action-btn-big" onClick={handlePlayPause}>
                        <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                    </button>
                    <button id="next" className="action-btn" onClick={nextSong}>
                        <i className="fas fa-forward"></i>
                    </button>
                </div>
            </div>} */}
            {/* {file_url && <div className="music-container" id="music-container">
                <div className="music-info">
                    <h4 id="title"></h4>
                    <div className="progress-container" id="progress-container">
                        <div className="progress" id="progress"></div>
                    </div>
                </div>

                <audio src={file_url} id="audio"></audio>

                <div className="img-container">
                    <img src="images/ukulele.jpg" alt="music-cover" id="cover" />
                </div>
                <div className="navigation">
                    <button id="prev" className="action-btn">
                        <i className="fas fa-backward"></i>
                    </button>
                    <button id="play" className="action-btn action-btn-big">
                        <i className="fas fa-play"></i>
                    </button>
                    <button id="next" className="action-btn">
                        <i className="fas fa-forward"></i>
                    </button>
                </div>
            </div>} */}
        </>
    )
}

export default Mp3AudioPlayer