import React from "react";
import {useNavigate } from 'react-router-dom';
import StoryStatusIcon from "./StoryStatusIcon";


const ChapterCard = (props) => {
  const {chapter, imageUrlPrefix, handleClick, idx} = props
  const navigate = useNavigate();
  const handleOnClick = ()=> {
    navigate('/chapter/'+chapter.id);
  }

  return (
    <div class="accordion-item">
      <h2 class="accordion-header" id={`headingOne_${idx}`}>
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseone_${idx}`}
          aria-expanded="true"
          aria-controls={`collapseone_${idx}`}
        >
          {chapter.chapter_number}. {chapter.chapter_title}
        </button>
      </h2>
      <div
        id={`collapseone_${idx}`}
        class="accordion-collapse collapse"
        aria-labelledby={`headingOne_${idx}`}
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body" onClick={handleOnClick}>
        {chapter.content}
        </div>
      </div>
      
    </div>
    // <div className="item features-without-image ">
    //   <div className="item-wrapper">
    //     <div className="item-content" onClick={handleOnClick}>
    //       <h5 className="mbr-card-title mbr-fonts-style mt-0 mb-3 mbr-number display-5">
    //         <strong>{chapter.chapter_number}. {chapter.chapter_title}</strong> <strong><StoryStatusIcon draft_mode={chapter.draft_mode}/></strong>
            
    //       </h5>
    //       <p className="card-text mbr-fonts-style mt-0 mb-0 display-7">
    //         {chapter.content.substring(0, 100)}....
    //       </p>
    //     </div>
    //   </div>
    // </div>
  );
};
export default ChapterCard