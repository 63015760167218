import React, { useState, useEffect, useCallback } from "react";
import "./SearchStoryForm.css";
import Loader from "./loader";
import { createChapter, createStory, searchStory } from "../api";
import StepByStepIndicator from "./stepByStepLoader";
import SearchStory from "./searchStory";
import { debounce, throttle } from 'lodash';

const SearchStoryForm = () => {
  const [story, setStory] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [isLoad, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [suggestedStories, setSuggestedStories] = useState(null)
  const [formData, setFormData] = useState({
    search_keyword: "",
    search_for: "title",
  });
  const searchKey = {
    title: "Title",
    genre: "Genre",
    age_range: "Age Range",
    create_name: "Creator Name",
  }

  // Define a throttled version of your API call
  const throttledApiCall = useCallback(throttle(async (newFormData) => {
    // Make your API call here with newFormData   
    
    if (newFormData['search_keyword']) {
      const newParam = {}
      newParam[newFormData['search_for']] = newFormData['search_keyword']
      const {data} = await searchStory(newParam)
      setSuggestedStories(data.length ?data:null)
    } else {
      setSuggestedStories(null)
    }
  }, 1000), []); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    throttledApiCall(newFormData);
  };  

  return (
    <>      
    <div>
      {showForm && (
        
        <section class="login_section">
          <div className="loginOuterDiv">
            <div className="container">
              <div className="login_wapper_outer">
              <div className="login_wapper d-flex align-items-center justify-content-center">
                <div className="input_wapper position-relative">
                  <header>Search Story</header>
                  <form action="#" class="form">
                    <div class="input-box">
                      {/* <label>Search By:</label> */}
                      <select className="select-dropdown" name="search_for" onChange={handleChange}>                        
                        {
                         Object.keys(searchKey).map(key=> <option value={key} key={key}>{searchKey[key]}</option>)
                        }
                      </select>
                      <input
                        type="text"
                        placeholder="Enter your keyword"
                        name="search_keyword"
                        value={formData.creator_name}
                        onChange={handleChange}
                      />
                    </div>                    
                  </form>
                </div>
                </div>
                {suggestedStories && <SearchStory results={suggestedStories} />}
              </div>
            </div>
            
          </div>
         
        </section>
        
      )}      
      
      </div>
    </>
  );
};

export default SearchStoryForm;
