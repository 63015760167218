import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing";
import LoginPage from "./pages/login";
import DashboardPage from "./pages/dashboard.jsx";
import "./styles/mobirise2.css";
import "./styles/bootstrap.min.css";
import "./styles/bootstrap-grid.min.css";
import "./styles/bootstrap-reboot.min.css";
import "./styles/animate.css";
import "./styles/style.css";
import "./styles/style(1).css";
import "./styles/mbr-additional.css";
import "./styles/mbr-additional2.css";
import StoryGallery from "./components/storyGallery.jsx";
import MenuList from "./components/menu.jsx";
import Story from "./components/story.jsx";
import StoryPerChapter from "./components/storyPerChapter.jsx";
import CreateStory from "./components/createStoryForm";
import SearchStoryForm from "./components/SearchStoryForm";
import "@fortawesome/fontawesome-free/css/all.css";
import ParentsLogin from "./pages/ParentsLogin.jsx";
import ParentsReg from "./pages/ParentsRegs";
import Footer from "./components/footer";
import Otp from "./components/Otp";
import Pagenotfound from "./Pagenotfound";
import { useEffect, useState } from "react";

function App() {
  return (
    <>
      <Router>
        <MenuList />
        <Routes>
          {/* <Route path="/home" element={<ParentsLogin />} /> */}
          {/* <Route path="/story-gallery" element={<StoryGallery />} /> */}
          <Route path="/story/:story_id" element={<Story />} />
          <Route path="/chapter/:chapter_id" element={<StoryPerChapter />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/create" element={<CreateStory />} exect={true} />
          <Route path="/search-story" element={<SearchStoryForm />} />
          <Route path="/" element={<StoryGallery />} />
          <Route path="/login" element={<ParentsLogin />} />
          <Route path="/registration" element={<ParentsReg />} />'
          <Route path="/Otp" element={<Otp />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
