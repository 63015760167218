import React, { useEffect, useState } from 'react';
import './toasts.css'

const Toasts = ({ messages, type, resetMessage }) => {
  const [toasts, setToasts] = useState([]);

  const createNotification = (message, type) => {
    const newToast = (
      <div key={Math.random()} className={`toast ${type}`}>
        {message}
      </div>
    );

    setToasts(prevToasts => [...prevToasts, newToast]);

    setTimeout(() => {
      setToasts(prevToasts => prevToasts.filter(toast => toast.key !== newToast.key));
    }, 3000);
  }
  useEffect(()=>{    
    if(messages) {
        createNotification(messages, type)
        resetMessage()
    }    
  },[messages])

  return (
      <div id="toasts">
        {toasts.map(toast => toast)}
      </div>      
  );
}

export default Toasts;