import React, { useEffect } from "react";
import { fetchStories } from "./../api";
import StoryCard from "./storyCard";
import Header from "./header";
import Features from "./features";
import config from "../../src/config";
import Mp3AudioPlayer from "./Mp3AudioPlayer";
import './storyGallery.css'
import Aboutus from "../components/Aboutus";

const StoryGallery = () => {
  const baseAPIUrl = config.API_BASE_URL;
  const [stories, setStories] = React.useState([]);
  const [storyInfo, setStoryInfo] = React.useState([]);
  const onSuccess = (response) => {
    setStories(response.data.results);
    const newInfo = response.data.results.map((story) => {
      return {
        id: story.id,
        genre: story.genre,
        number_of_chapters: story.number_of_chapters,
        title: story.title,
        media: story?.media[0]?.image_file_url
      };
    });
    setStoryInfo(newInfo);
    localStorage.setItem("stories", JSON.stringify(newInfo));
  }
  useEffect(() => {
    const getPosts = async () => {
      await fetchStories().then((data)=> {
        onSuccess(data)
      }, (error)=> {
        setStories([]);
      });
    };

    getPosts();
  }, []);

  return (
    <>
      <Header />
    <Aboutus />
            <section
        data-bs-version="5.1"
        className="features4 start cid-u40iKFICvC"
        id="features04-1r"
      >
        <div className="container"> 
          <div className="justify-content-center">
            <div className="col-12 content-head">
              <div className="mbr-section-head">
                <h4 className="exploreSectionHeadding">
                  Explore Our Enchanted Library of<strong> Adventures</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="d-md-flex flex-wrap adventures">
            {stories &&
              stories
                .sort((a, b) => (a.id > b.id ? -1 : 1))
                .slice(0, 8)
                .map((story, index) => <StoryCard story={story} key={index} baseAPIUrl={baseAPIUrl}/>)}
          </div>
        </div>
      </section>
      <Features storyInfo={storyInfo} baseAPIUrl={baseAPIUrl}/>
    </>
  );
};

export default StoryGallery;
