import React, { useEffect, useRef, useState } from "react";
import "./BookFlip.css";
import TextToSpeech from "./TextToSpeech";
import TextToParagraph from "./TextToParagraph";
import config from "../config";
import Mp3AudioPlayer from "./Mp3AudioPlayer";

const transformData = (data) => {
  const { media, chapters, ...storyDetails } = data;

  // Process main story media
  const storyMedia = media.map((item) => ({
    ...item,
    ...storyDetails,
  }));

  // Process chapters and their media
  const chapterDetails = chapters.flatMap((chapter) => {
    // Combine chapter details with each media item
    const chapterMedia = chapter.media.map((mediaItem) => ({
      ...mediaItem,
      chapter: chapter.id,
      ...storyDetails,
    }));

    // Add chapter information (excluding media array)
    const { media, ...restChapter } = chapter;
    return [
      ...chapterMedia,
      { ...restChapter, story: data.id, ...storyDetails },
    ];
  });

  return [...storyMedia, ...chapterDetails];
};
const ChapterContent = (props) => {
  const { chapter, pageNumber, imageUrlPrefix, currentIndex, audio_file_url, thumbnail } = props;
  const [enablePlayer, setEnablePlayer] = useState(false);
  useEffect(() => {
    setEnablePlayer(pageNumber-2 == currentIndex);
  }, [currentIndex]);

  return pageNumber && pageNumber % 2 == 0 ? (
    <div className="audio">
      <h1 className="chapter_title">{chapter.chapter_title} </h1>     
      
      <div className="overflow" style={{ height: "calc(100vh - 310px)" }}>
        <TextToParagraph text={chapter.content} />{" "}
        <h4 className="pageNo">{pageNumber}</h4>
      </div>
      {enablePlayer && (
        <Mp3AudioPlayer file_url={`${audio_file_url}`} thumbnail={thumbnail}/>
      )}
    </div>
  ) : (
    <div className="story_images">
      <img
        className="w-100 animate__animated animate__delay-1s animate__fadeIn"
        src={`${chapter.image_file_url}`}
        alt="Mobirise Website Builder"
      />
      <h4 className="pageNo">{pageNumber}</h4>
    </div>
  );
};
const BookFlip = (props) => {
  const { API_BASE_URL } = config;
  const { story } = props;
  const pagesRef = useRef([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setUpdatedData(transformData(story));
  }, [story]);

  useEffect(() => {
    // This replaces the 'DOMContentLoaded' part
    pagesRef.current.forEach((page, i) => {
      if (i % 2 === 0) {
        page.style.zIndex = pagesRef.current.length - i;
      }
    });
  }, [story]); // Empty array ensures this effect is only run once after initial render

  const handleClick = (pageNum, index) => {
    if (pageNum === updatedData.length) return;
    if (pageNum % 2 === 0) {
      pagesRef.current[index]?.classList.remove("flipped");
      pagesRef.current[index - 1]?.classList.remove("flipped");
      setCurrentIndex(pageNum-4)
    } else {
      setCurrentIndex(pageNum-1)
      pagesRef.current[index]?.classList.add("flipped");
      pagesRef.current[index + 1]?.classList.add("flipped");
    }
  };
  const coverPage = (
    <div
      ref={(el) => (pagesRef.current[0] = el)}
      className="page"
      onClick={() => handleClick(1, 0)}
    >
      <h1 className="chapterTitle">
        <strong>{story.title}</strong>{" "}
      </h1>
      <div className="flipbook_cover_img">
          {/* {story.creator_name && <h3 className="creatorName">creator: {story.creator_name}</h3>} */}
        <div className="story_img">
          {story && story.media && (
            <img
              className="w-100 animate__animated animate__delay-1s animate__fadeIn"
              src={`${story.title}`}
              alt="Mobirise Website Builder"
            />
          //   <img
          //   className="w-100 animate__animated animate__delay-1s animate__fadeIn"
          //   src={`${process.env.PUBLIC_URL}/images/up-in-the-air.webp`}
          //   alt="Mobirise Website Builder"
          // />
          )}
           {story.creator_name && <h3 className="creatorName">creator: {story.creator_name}</h3>}
        </div>
      </div>
    </div>
  );

  return (
    <div className="book container">
       
      <div id="pages" className="pages">
        {updatedData &&
          updatedData.map((content, i) => (
            <div
              key={i}
              ref={(el) => (pagesRef.current[i] = el)}
              className="page"
              onClick={() => handleClick(i + 1, i)}
              style={{ zIndex: i % 2 == 0 ? updatedData.length - i : "" }}
            >
              {i === 0 && story && coverPage}
              {i === 1 && (
                <div className="flipbook_cover_img">
                  <img
                    className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                    src={`${updatedData[i].image_file_url}`}
                    alt={story.title}
                  />
                  {/* <img
            className="w-100 animate__animated animate__delay-1s animate__fadeIn"
            src={`${process.env.PUBLIC_URL}/images/up-in-the-air.webp`}
            alt="Mobirise Website Builder"
          /> */}
                  <h4 className="pageNo">{i}</h4>
                </div>
              )}
              {i > 1 && (
                <ChapterContent
                  chapter={content}
                  pageNumber={i}
                  imageUrlPrefix={API_BASE_URL}
                  audio_file_url={updatedData[i-1].audio_file_url}
                  thumbnail={updatedData[i-1].image_file_url}
                  currentIndex={currentIndex}
                />
              )}
            </div>
          ))}
      </div>
    </div>
    
  );
};

export default BookFlip;
