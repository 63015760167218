import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStory, publishAll } from "./../api";
import ChapterCard from "./chapterCard";
import LoaderIcon from "./LoaderIcon";
import ActionConfirmation from "./ActionConfirmaton";
import BookFlip from "./BookFlip";
import config from "../config";
import Toasts from "./toasts";

const Story = () => {
  const { API_BASE_URL } = config;
  let { story_id } = useParams();
  const [story, setStory] = useState([]);
  // const API_BASE_URL = "http://localhost:8003";
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [isPublishedAPISucess, setIsPublishedAPISucess] = useState(false);
  const [isStoryPublished, setIsStoryPublished] = useState(true);
  const [startReading, setStartReading] = useState(false);
  const [notification, setNotification] = useState({ messages: "", type: "" });

  useEffect(() => {
    const getStory = async (story_id) => {
      const { data } = await fetchStory(story_id);
      setStory(data);
    };
    getStory(story_id);
    setIsApiLoading(false);
  }, [story_id]);

  const publishAllChapters = async () => {
    if (!story.id) return;
    setIsApiLoading(false);
    await publishAll(story.id).then(
      (response) => {
        const data = response.data;
        setNotification({
          messages: `Hurray!! ${story.title} has been publised successfully!`,
          type: "success",
        });
        if (data) {
          setIsApiLoading(false);
          setIsStoryPublished(true);
          setIsPublishedAPISucess(true);
        }
      },
      (error) => {
        setNotification({
          messages: `Oops!! Something went wrong.`,
          type: "error",
        });
      }
    );
  };

  const onClose = () => {
    setIsPublishedAPISucess(false);
  };

  useEffect(() => {
    if (story.chapters && story.chapters.length) {
      const isPublished = !story.chapters
        .filter((chapter) => chapter.draft_mode)
        .some((chapter) => chapter);
      setIsStoryPublished(isPublished);
    }
  }, [story]);

  return (
    <>
      <section
        data-bs-version="5.1"
        className="features028 cid-u40j8N9GuG"
        id="features028-1u"
      >
        {isApiLoading && <LoaderIcon />}
        {(!isStoryPublished || !startReading) && story && story.title && (
          <div className="single_story_page">
            <div className="container">
              <h1>
                <strong>{story.title}</strong>{" "}
                <div>
                  <sub>creator: {story.creator_name || "Anonymouse"}</sub>
                </div>
              </h1>
              <div className="single_story_wapper d-lg-flex align-items-center justify-content-between">
                <div className="story_img">
                  {story && story.media && (
                    <img
                      className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                      src={`${story.media[0].image_file_url}`}
                      alt="Mobirise Website Builder"
                    />
                    // <img
                    //   className="w-100 animate__animated animate__delay-1s animate__fadeIn"
                    //   src={`${process.env.PUBLIC_URL}/images/bookCover.png`}
                    //   alt="Mobirise Website Builder"
                    // />
                  )}
                </div>
                <div className="about_story">
                  <div class="accordion" id="accordionExample">
                    {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          1. The Mysterious Map
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne1"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          In a small town nestled between rolling hills and lush
                          forests, a group of friends stumbled upon a dusty old
                          map hidden in the attic of an abandoned house. The
                          map, marked with intricate symbols and cryptic
                          messages, led them to a secret underground tunnel
                          where a treasure chest lay waiting to be discovered.
                          But as they opened the chest, they found not gold or
                          jewels, but a set of magical Hot Wheels cars that
                          glowed with an otherworldly light.
                        </div>
                      </div>
                      
                    </div> */}
                    {story &&
                      story.chapters &&
                      story.chapters.map((chapter, idx) => (
                        <ChapterCard
                          chapter={chapter}
                          API_BASE_URL={API_BASE_URL}
                          idx={idx}
                          key={idx}
                        />
                      ))}
                  </div>
                  {/* <div className="points d-md-flex flex-wrap justify-content-between">
                    {story &&
                      story.chapters &&
                      story.chapters.map((chapter, idx) => (
                        <ChapterCard
                          chapter={chapter}
                          API_BASE_URL={API_BASE_URL}
                          idx={idx}
                          key={idx}
                        />
                      ))}
                  </div> */}
                  {!isStoryPublished && (
                    <button
                      className="publish_btn"
                      onClick={publishAllChapters}
                    >
                      {" "}
                      <span>Publish</span>
                    </button>
                  )}
                  {isStoryPublished && (
                    <button
                      className="publish_btn"
                      onClick={() => setStartReading(true)}
                    >
                      {" "}
                      <span> Start Reading...</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {startReading && story && story.title && <BookFlip story={story} />}
      </section>
      <Toasts
        messages={notification.messages}
        type={notification.type}
        resetMessage={() => {}}
      />
    </>
  );
};

export default Story;
