import React from "react";

const DashboardPage = () => {
  return (
    <div className="form-container">
      <form>
        <div className="form-group">
          <label for="feedback">Your Feedback:</label>
          <textarea id="feedback" rows="4" required></textarea>
        </div>
        <div className="form-group">
          <label for="category">Category:</label>
          <select id="category" required>
            <option value="">Please choose an option</option>
            <option value="feedback">Feedback</option>
            <option value="inquiry">Inquiry</option>
            <option value="support">Support</option>
          </select>
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default DashboardPage;
